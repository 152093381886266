import SliderGallery_SliderGalleryCircleSkinComponent from '@wix/thunderbolt-elements/src/components/NativeGalleries/SliderGallery/viewer/skinComps/SliderGalleryCircleSkin/SliderGalleryCircleSkin.skin';


const SliderGallery_SliderGalleryCircleSkin = {
  component: SliderGallery_SliderGalleryCircleSkinComponent
};


export const components = {
  ['SliderGallery_SliderGalleryCircleSkin']: SliderGallery_SliderGalleryCircleSkin
};


// temporary export
export const version = "1.0.0"
